import moment from 'moment'
import Encoding from 'encoding-japanese'

export function dateFormatted(value, short = true) {
  let format
  let date

  if (short) {
    format = 'YYYY.M.D'
  } else {
    format = 'YYYY.M.D HH:mm:ss'
  }

  if (value) {
    date = moment(value)

    if (date !== 'Invalid Date') {
      return date.format(format)
    }
  }

  return ''
}

export function formatPercent(value) {
  return (value * 100).toFixed(2)
}

export function formatRank(value) {
  if (value >= 0.05) return 'A'
  if (value >= 0.03 && value < 0.05) return 'B'
  if (value >= 0.01 && value < 0.03) return 'C'
  if (value < 0.01) return 'D'
}

export function sortDeepArrayByValue(array) {
  return array.sort(function(a, b) {
    return b.value - a.value
  })
}

export function getMaxY(data) {
  /* eslint-disable */
  const array = []
  for (const [indexCategory, category] of Object.entries(data)) {
    for (const [indexCharacter, character] of Object.entries(category)) {
      array.push(character)
    }
  }
  return Math.ceil(Math.max(...array) * 100)
}

export function transpose(a) { // func transpose array
  return Object.keys(a[0]).map(function(c) {
    return a.map(function(r) { return r[c] })
  })
}

export function addStyleDisplayToClass(arrayClass, value) {
  for (let i = 1; i <= arrayClass.length; i++) {
    arrayClass[i - 1].style.display = value
  }
}

export function trimValue(data) {
  if (Array.isArray(data)) {
    return data.map(item => {
      return {
        ...item,
        value: item.value.trim()
      }
    })
  } else {
    return {
      ...data,
      value: data.value.trim()
    }
  }
}

export const convertUnicodeToShiftJIS = function(data) {
  const uniArray = Encoding.stringToCode(data)
  const sjisArray = Encoding.convert(uniArray, 'SJIS', 'UNICODE')
  const unit8Array = new Uint8Array(sjisArray)
  return unit8Array
}
